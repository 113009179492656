export const data = {
  panelImageName: "personal-bg-1.png",
  divisorText: "Personal Trainers",
  content: {
    imageName: "personal-trainer-2.jpg",
    title: "Como ser nosso cliente?",
    text:
      "Se você esta trabalhando como Personal Trainer e deseja regularizar e proteger os seus ganhos, deixe seus dados. Entraremos em contato e faremos uma análise personalizada para garantir a menor carga tributária.",
    options: [
      "Análise dos Riscos",
      "Assessoria Financeira",
      "Orientação Tributária",
    ],
    contactText:
      "Tenha uma contabilidade personalizada, cadastre-se e receba nosso contato",
  },
}
