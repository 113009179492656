import React from "react"

import SEO from "../components/seo"

import Layout from "../components/Layout"
import BlankPage from "../components/BlankComponents/BlankPage"
import { data } from "../pagesContent/personal"

const PersonalTrainers = () => (
  <Layout>
    <SEO title="Personal Trainers" />
    <BlankPage data={data} />
  </Layout>
)

export default PersonalTrainers
